import React, { useEffect, useRef } from 'react';
import './Splash.css'; // Assuming your CSS styles are defined here

const Splash = () => {
  const canvasRef = useRef(null);
  let mouseMoved = false;
  let animationFrameId;

  const getRainbowColor = (value, total) => {
    const hue = (value / total) * 360; // Dividing by the total number of points and multiplying by 360 gives a full spectrum of colors
    return `hsl(${hue}, 100%, 50%)`; // Keeping saturation and lightness constant
  };


  const pointer = {
    x: 0.5 * window.innerWidth,
    y: 0.5 * window.innerHeight,
  };

  const params = {
    pointsNumber: 40,
    widthFactor: 0.3,
    mouseThreshold: 0.6,
    spring: 0.4,
    friction: 0.5,
  };

  const trail = new Array(params.pointsNumber).fill(null).map(() => ({
    x: pointer.x,
    y: pointer.y,
    dx: 0,
    dy: 0,
  }));

  const updateMousePosition = (eX, eY) => {
    pointer.x = eX;
    pointer.y = eY;
  };

  const setupCanvas = () => {
    const canvas = canvasRef.current;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  };

  const update = (t) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // for intro motion
    if (!mouseMoved) {
      pointer.x = (0.5 + 0.3 * Math.cos(0.002 * t) * Math.sin(0.005 * t)) * window.innerWidth;
      pointer.y = (0.5 + 0.2 * Math.cos(0.005 * t) + 0.1 * Math.cos(0.01 * t)) * window.innerHeight;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    trail.forEach((p, pIdx) => {
      const prev = pIdx === 0 ? pointer : trail[pIdx - 1];
      const spring = pIdx === 0 ? 4 * params.spring : params.spring;
      p.dx += (prev.x - p.x) * spring;
      p.dy += (prev.y - p.y) * spring;
      p.dx *= params.friction;
      p.dy *= params.friction;
      p.x += p.dx;
      p.y += p.dy;
    });

    ctx.lineCap = "round";
    ctx.beginPath();
    ctx.moveTo(trail[0].x, trail[0].y);

    for (let i = 1; i < trail.length - 1; i++) {
      const xc = 0.5 * (trail[i].x + trail[i + 1].x);
      const yc = 0.5 * (trail[i].y + trail[i + 1].y);
      ctx.strokeStyle = getRainbowColor(i, trail.length); // Set the stroke color
      ctx.lineWidth = params.widthFactor * (params.pointsNumber - i);
      ctx.quadraticCurveTo(trail[i].x, trail[i].y, xc, yc);
      ctx.stroke();
    }

    animationFrameId = window.requestAnimationFrame(update);
  };

  useEffect(() => {
    setupCanvas();
    animationFrameId = window.requestAnimationFrame(update);

    const handleMouseMove = (e) => {
      mouseMoved = true;
      updateMousePosition(e.pageX, e.pageY);
    };

    const handleTouchMove = (e) => {
      mouseMoved = true;
      updateMousePosition(e.targetTouches[0].pageX, e.targetTouches[0].pageY);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('resize', setupCanvas);

    return () => {
      window.cancelAnimationFrame(animationFrameId);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('resize', setupCanvas);
    };
  }, []);

  return (
    <>
      <canvas ref={canvasRef}></canvas>

    </>
  );
};

export default Splash;
